@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.sectionHero {
  position: relative;
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow-y: hidden;
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--colorGrey800);
  background-color: var(--colorGrey100);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
  padding: 15px;
  border-radius: var(--borderRadius);
}

.actionBarForProductLayout {
  width: 100%;
  margin: 0 0 24px 0;
}

.actionBarForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  composes: h3 from global;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  composes: h5 from global;
  font-size: 16px;
  flex-shrink: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
  color: var(--colorGrey800);
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;
  }
}

.editIcon {
  margin: 0px 7px 0 0;

  & > g {
    & > g {
      stroke: var(--colorBlack);
    }
  }
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidth);
    padding: 0;
  }
}

.mainColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    /* contentContainer's full width - (orderColumn + paddings + margin) */
    max-width: calc(100% - 460px);
    width: 100%;
    padding-right: 47px;
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    padding-top: 30px;
    max-width: 460px;
    width: 100%;
    flex-shrink: 0;
  }
}
.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 12px;
  & > * {
    font-size: 12px;
    line-height: 100%;
    color: #605f5f;
  }
  & > a {
    color: #605f5f;
    transition: var(--transitionStyle);
    &:hover {
      color: var(--marketplaceColor);
      transition: var(--transitionStyle);
    }
  }
  & > span {
    color: #605f5f;
  }
  & > svg {
    fill: none;
    height: 20px;
    width: 20px;
  }
}

.contentWrapperForProductLayout {
  margin: 0 0 56px 0;
  padding: 24px;

  @media (--viewportMedium) {
    width: 100%;
    max-width: calc(var(--contentMaxWidth) + 16px + 36px + 36px);
    margin: 0 auto 56px auto;
  }

  @media (--viewportLarge) {
    display: flex;
    flex-direction: column;
    padding: 36px 36px 0 36px;
    margin: 0 auto 117px auto;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-top: 80px;
    margin-left: 60px;
    border-left: 1px solid var(--colorGrey100);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.orderPanel {
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.productOrderPanel {
  @media (--viewportLarge) {
    display: block;
  }
}
.orderPanelTitle {
  /* Font */
  font-weight: var(--fontWeightBold);
  color: var(--textColor);
  font-size: 34px;
  line-height: 120%;
  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flexbox would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  color: var(--colorBlack);
  font-family: var(--fontFamily);
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0;
  margin-bottom: 24px;
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: p from global;
  font-size: 20px;
  line-height: 110%;
  padding: 0;
  font-family: var(--fontFamilySecondary);
  font-weight: var(--fontWeightMedium);
  color: var(--textColor);
  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 15px;
  }
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 1px 0 5px 0;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin: 0;
  }
}

.ingress {
  composes: p from global;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionDetails,
.sectionMultiEnum {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}
.sectionDetails {
  padding: 12px 0 0 0px;
  margin-top: 24px;
  border-top: solid 1px rgba(232, 232, 232, 1);

  & .details {
    margin: 0;
    /* border: solid 1px rgba(232, 232, 232, 1); */
    border-radius: 10px;

    & .detailsRow {
      display: inline-flex;
      width: 100%;
      padding: 5px 0px;
      line-height: 36px;
      list-style: none;
      font-weight: var(--fontWeightRegular);
      &:last-child {
        border-bottom: none;
      }
      & > span {
        width: 50%;
        font-size: 12px;
        line-height: 20px;
        color: rgba(88, 87, 87, 1);

        @media (--viewportLarge) {
          width: 80%;
        }

        &.detailLabel {
          width: 50%;
          color: rgba(150, 150, 150, 1);
          font-size: 12px;
          line-height: 20px;
          letter-spacing: -0.09px;

          @media (--viewportLarge) {
            width: 40%;
          }
        }
      }
    }
  }
}
.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.sectionReviews,
.sectionAuthor {
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    margin-bottom: 7px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 24px 0 48px 0;
  }
  @media (--viewportLarge) {
    margin: 0 0 40px 0;
    padding-bottom: 4px;
  }
}

.mobileHeading {
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.productDesktopHeading {
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.mainContentWrapper {
  flex-direction: column;

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
  }
}
.listingPageContentTab {
  margin-top: 52px;
  & .tabSect {
    display: flex;
    align-items: center;
    gap: 40px;
    border-bottom: solid 1px var(--colorGrey100);
    overflow-x: auto;
    @media (--viewportMedium) {
      gap: 80px;
    }
    & > span {
      position: relative;
      color: #807e7e;
      font-size: 16px;
      line-height: 110%;
      padding: 0 0 10px 0;
      cursor: pointer;
      white-space: nowrap;
      @media (--viewportMedium) {
        font-size: 20px;
      }

      &.active {
        color: var(--textColor);
        &::after {
          position: absolute;
          content: '';
          width: 100%;
          height: 1px;
          background-color: var(--textColor);
          bottom: 0;
          left: 0;
        }
      }
    }
  }
  & .tabContent {
    padding: 32px 0;
    & p {
      color: var(--labelColor);
      font-size: 18px;
      line-height: 130%;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.protectionSec {
  background-color: var(--marketplaceColor);
  border-radius: 6px;
  padding: 15px 10px;
  display: flex;
  align-items: center;

  & .protectionInfo {
    padding-left: 10px;
    & > h2 {
      font-size: 16px;
      line-height: 100%;
      color: var(--colorWhite);
      font-weight: var(--fontWeightSemiBold);
      margin: 0 0 5px 0;
      font-family: var(--fontFamilySecondary);
      @media (--viewportMedium) {
        font-size: 18px;
      }
    }
    & > p {
      font-family: var(--fontFamilySecondary);
      margin: 0;
      font-size: 14px;
      line-height: 100%;
      color: var(--colorWhite);
      font-weight: var(--fontWeightRegular);
      @media (--viewportMedium) {
        font-size: 16px;
      }
    }
  }
}
.paymentMethods {
  margin-top: 12px;
  float: left;
  width: 100%;
  & > h2 {
    font-size: 20px;
    line-height: 110%;
    padding: 0;
    font-family: var(--fontFamilySecondary);
    font-weight: var(--fontWeightMedium);
    color: var(--textColor);
    margin-top: 0;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 15px;
    }
  }
  & .paymentMode {
    float: left;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 24px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 9px;
    @media (--viewportSmall) {
      grid-template-columns: repeat(7, 1fr);
    }
    & > img {
      max-width: 100%;
    }
  }
}
.condition {
  float: left;
  width: 100%;
  margin-bottom: 22px;
  font-size: 14px;
  font-family: var(--fontFamilySecondary);
  color: var(--colorBlack);
  font-weight: var(--fontWeightRegular);
  @media (--viewportMedium) {
    font-size: 16px;
  }
  & > strong {
    font-weight: var(--fontWeightSemiBold);
  }
}
